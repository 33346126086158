<template>
    <div v-if="displayable">
        <el-menu-item v-if="displayOnlyOneChild" :index="getNextFullPath(onlyOneChild)" :route="onlyOneChild">
            <menu-title slot="title" :route="onlyOneChild"></menu-title>
        </el-menu-item>

        <el-submenu :index="fullPath" v-else-if="hasChildren(route.children)">
            <menu-title slot="title" :route="route"></menu-title>
            <sub-menu v-for="child in route.children"
                      :route="child"
                      :key="getNextFullPath(child)"
                      :fullPath="getNextFullPath(child)">
            </sub-menu>
        </el-submenu>

        <el-menu-item :index="fullPath" :route="route" v-else>
            <menu-title slot="title" :route="route"></menu-title>
        </el-menu-item>
    </div>
</template>

<script>
    import path from 'path';

    const MenuTitle = {
        functional: true,
        render: (h, ctx) => {
            const {icon, title} = ctx.props.route.meta;
            return (<template><i class={icon}/><span>{title}</span></template>)
        }
    };

    export default {
        name: "SubMenu",
        components: {
            MenuTitle
        },
        data() {
            return {
                onlyOneChild: null
            }
        },
        props: {
            route: {
                type: Object,
                required: true
            },
            fullPath: String
        },
        methods: {
            hasChildren(children) {
                return children && children.length;
            },
            onlyOneVisibleChild(children = [], parent) {
                const showingChildren = children.filter(item => {
                    if (item.hidden) {
                        return false
                    } else {
                        this.onlyOneChild = item;
                        return true
                    }
                });

                if (showingChildren.length === 1) {
                    return true
                }

                // 如果没有子路由则默认显示父路由
                if (showingChildren.length === 0) {
                    this.onlyOneChild = {...parent, path: '', withoutVisibleChild: true};
                    return true
                }
                return false
            },
            getNextFullPath(route) {
                return path.resolve(this.fullPath, route.path);
            }
        },
        computed: {
            displayable() {
                const {meta, hidden} = this.route;
                return !hidden && meta && meta.isMenu === true;
            },
            displayOnlyOneChild() {
                const {children, alwaysShow} = this.route;
                return !alwaysShow
                    && this.onlyOneVisibleChild(children, this.route)
                    && (!this.onlyOneChild.children || this.onlyOneChild.withoutVisibleChild);
            }
        }
    };
</script>
